import React from "react";

function MainWid() {
  return (
    <section className="contact-area pt-120 pb-120">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-6 col-lg-10">
            <div
              className="contact-form-wrap"
              style={{
                backgroundImage: `url(${require("../../assets/img/images/contact_form_bg.jpg")})`,
              }}
            >
              <h2 className="title">Contact With Us</h2>
              {/* <p>Send us a message and we' ll respond as soon as possible</p> */}<a href="tel:+14758009366">+1-475-800-9366</a><br />
                      <a href="mailto:erick.a@academyd-signs.com">erick.a@academyd-signs.com</a>
              <p>This Site and its domain is available, contact us for more information</p>
              
                      
                    
              <form action="#" className="contact-form">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-grp">
                      <input
                        id="firstName"
                        type="text"
                        placeholder="First Name*"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-grp">
                      <input
                        id="lastName"
                        type="text"
                        placeholder="Last Name*"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-grp">
                      <input
                        id="email"
                        type="email"
                        placeholder="Email Address*"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-grp">
                      <input
                        id="phone"
                        type="text"
                        placeholder="Phone Number*"
                      />
                    </div>
                  </div>
                </div>
                <div className="form-grp">
                  <input id="subject" type="text" placeholder="Subject" />
                </div>
                <div className="form-grp">
                  <textarea
                    id="message"
                    placeholder="Your Message here"
                  ></textarea>
                </div>
                <button className="btn" type="submit">
                  Send Message
                </button>
              </form>
            </div>
          </div>
          <div className="col-xl-6 col-lg-10">
            <div className="contact-info-wrap">
              <h2 className="title">Need Any Help?</h2>
              {/* <p>Call us or message and we' ll respond as soon as possible</p> */}
              <p>This Site and its domain is available, contact us for more information</p>
              <ul className="list-wrap">
                <li>
                  <div className="contact-info-item">
                    <div className="icon">
                      <i className="fas fa-phone-alt"></i>
                    </div>
                    <div className="content">
                      <a href="tel:+14758009366">+1-475-800-9366</a>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="contact-info-item">
                    <div className="icon">
                      <i className="fas fa-envelope"></i>
                    </div>
                    <div className="content">
                      <a href="mailto:erick.a@academyd-signs.com">erick.a@academyd-signs.com</a>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="contact-info-item">
                    <div className="icon">
                      <i className="fas fa-map-marker-alt"></i>
                    </div>
                  <a href="">
                    <div className="content">
                      {/* <p>
                      694 Baldwin St, Waterbury <br /> CT 06706, Estados Unidos
                      </p> */}
                    </div>
                  </a>
                  </div>
                </li>
              </ul>
            </div>
            <div id="contact-map">
              <iframe
                title="map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2996.4655377574263!2d-72.8597393!3d41.320489200000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e7d975bb47b653%3A0x7e040bbfcf174588!2sAcademy%20D-signs%20LLC!5e0!3m2!1ses-419!2sco!4v1736781480912!5m2!1ses-419!2sco"
                allowFullScreen
                loading="lazy"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default MainWid;
