import React from 'react'
import './FloatingButton.css'

function FloatingButton() {
  return (
    <div className="floating-button">
      <a href="/contact">This Site is available</a>

    </div>
  )
}

export default FloatingButton
