import React from "react";

function FooterOne() {
  return (
    <footer>
      <div
        className="footer-area footer-bg"
        style={{
          backgroundImage: `url(${require("../../../assets/img/bg/footer_bg.jpg")})`,
        }}
      >
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-7">
                <div className="footer-widget">
                  <h2 className="fw-title">About Us</h2>
                  <div className="footer-content">
                    <p>
                    We're passionate about providing top-tier roofing services. Our commitment to excellence is evident in every project we undertake.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-5 col-sm-6">
                <div className="footer-widget">
                  <h2 className="fw-title">Our Services</h2>
                  <div className="footer-link">
                    <ul className="list-wrap">
                      <li>
                        <a href="/services/services-roofing">
                          <i className="fas fa-angle-double-right"></i>Roofing Contractor
                        </a>
                      </li>
                      <li>
                        <a href="/services/services-siding">
                          <i className="fas fa-angle-double-right"></i>Siding Contractor
                        </a>
                      </li>
                      <li>
                        <a href="/services/services-gutter">
                          <i className="fas fa-angle-double-right"></i>Gutter Installation
                        </a>
                      </li>
                      {/* <li>
                        <a href="/project">
                          <i className="fas fa-angle-double-right"></i>Specialty
                          Metal
                        </a>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>
              {/* <div className="col-xl-2 col-lg-4 col-md-6 col-sm-6">
                <div className="footer-widget">
                  <h2 className="fw-title">Quick Links</h2>
                  <div className="footer-link">
                    <ul className="list-wrap">
                      <li>
                        <a href="/contact">
                          <i className="fas fa-angle-double-right"></i>Terms &
                          Conditions
                        </a>
                      </li>
                      <li>
                        <a href="/contact">
                          <i className="fas fa-angle-double-right"></i>Tax & Vat
                        </a>
                      </li>
                      <li>
                        <a href="/contact">
                          <i className="fas fa-angle-double-right"></i>Refund
                          Policy
                        </a>
                      </li>
                      <li>
                        <a href="/contact">
                          <i className="fas fa-angle-double-right"></i>Typically
                          Solutions
                        </a>
                      </li>
                      <li>
                        <a href="/contact">
                          <i className="fas fa-angle-double-right"></i>Our
                          Process
                        </a>
                      </li>
                      <li>
                        <a href="/contact">
                          <i className="fas fa-angle-double-right"></i>Contact
                          Us
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div> */}
              <div className="col-xl-4 col-lg-4 col-md-6">
              <h2 className="fw-title">Our Location</h2>
              <div className='footer-map'>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2996.4655377574263!2d-72.8597393!3d41.320489200000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e7d975bb47b653%3A0x7e040bbfcf174588!2sAcademy%20D-signs%20LLC!5e0!3m2!1ses-419!2sco!4v1736781480912!5m2!1ses-419!2sco" width="600" height="450"  allowfullscreen loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                
              </div>
              </div>
            </div>
            <div className="footer-logo-area">
              <div className="row align-items-center">
                <div className="col-xl-3 col-lg-3 col-md-4">
                  <div className="logo">
                    <a href="/">
                      <img
                        src={require(`../../../assets/img/logo/logo.png`)}
                        alt=""
                      />
                    </a>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-4">
                  <div className="footer-contact">
                    <div className="icon">
                      <i className="fas fa-phone-alt"></i>
                    </div>
                    <div className="content">
                      <span>Phone No</span>
                      <a href="tel:+14758009366">+1-475-800-9366</a>
                    </div>
                  </div>
                </div>
                <div className="col-xl-5 col-lg-5 col-md-4">
                  <div className="footer-social">
                    <h2 className="title">Contact Us:</h2>
                    <ul className="list-wrap">
                      <li>
                        
                        <a href=""><i className="fas fa-envelope"></i></a>
                      </li>
                      <li>
                        <a href="">
                          <i className="fas fa-map-marker-alt"></i>
                        </a>
                      </li>
                      
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="copyright-text">
                  <p>© Copyright 2023. All Right Reserved</p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="footer-bootom-menu">
                  <ul className="list-wrap">
                    <li>
                      <a href="https://www.academyd-signs.com/">Powered by:</a>
                    </li>
                    <li>
                      <a href="https://www.academyd-signs.com/">Academy D-signs</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default FooterOne;
